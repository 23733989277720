import { Dialog, DialogContent, Grid } from '@mui/material';
import { AutocompleteField, DialogActions, DialogTitle, FieldWithServerError, NumberField, } from '@platform/front-ui';
import { disableSubmitOnEnterKeyPress, stopPropagation, useYup } from '@platform/front-utils';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useOrganizationFieldContext } from '../../../../hooks';
import { AttributeField, AttributeFieldCommon, AttributeFieldRequired, } from '../../../../types';
import { testInn, testKpp, testOgrn } from '../../../../utils';
var getInvalidAttributeMessage = function (formatMessage, attribute) {
    return formatMessage({ id: 'formio.organization.invalidAttribute' }, {
        attribute: formatMessage({ id: "formio.organization.attribute." + attribute }),
    });
};
export var OrganizationCreateModal = observer(function () {
    var _a = useOrganizationFieldContext(), id = _a.id, _b = _a.attributeFields, attributeFields = _b === void 0 ? [] : _b, model = _a.model, setIsChanged = _a.setIsChanged;
    var stateOptions = model.stateOptions, opfOptions = model.opfOptions, closeCreateModal = model.closeCreateModal, createOrganization = model.createOrganization, loadCreationOptions = model.loadCreationOptions;
    var formatMessage = useIntl().formatMessage;
    useEffect(function () {
        loadCreationOptions();
    }, []);
    var optionalAttributeFields = attributeFields.filter(function (attribute) { return Object.keys(AttributeFieldRequired).indexOf(attribute) === -1; });
    var initialValues = {
        fullName: '',
        inn: '',
    };
    var invalidInnMessage = getInvalidAttributeMessage(formatMessage, AttributeField.inn);
    var invalidKppMessage = getInvalidAttributeMessage(formatMessage, AttributeField.kpp);
    var invalidOgrnMessage = getInvalidAttributeMessage(formatMessage, AttributeField.ogrn);
    var Yup = useYup().Yup;
    var validationSchema = Yup.object({
        fullName: Yup.string().required(),
        shortName: Yup.string(),
        inn: Yup.string().required().test('innValid', invalidInnMessage, testInn),
        legalAddress: Yup.string(),
        fio: Yup.string(),
        kpp: Yup.string().test('kppValid', invalidKppMessage, testKpp),
        ogrn: Yup.string().test(invalidOgrnMessage, invalidOgrnMessage, testOgrn),
        opf: Yup.object().nullable(),
        state: Yup.object().nullable(),
    });
    var cancelButton = {
        onClick: closeCreateModal,
    };
    var submitButton = {
        type: 'submit',
        text: formatMessage({ id: 'common.create' }),
    };
    var additionalFields = optionalAttributeFields.map(function (attribute) {
        var field = React.createElement(React.Fragment, null);
        var label = formatMessage({ id: "formio.organization.attribute." + attribute });
        switch (attribute) {
            case AttributeFieldCommon.kpp:
            case AttributeFieldCommon.ogrn:
                field = React.createElement(NumberField, { name: attribute, label: label });
                break;
            case AttributeFieldCommon.fio:
            case AttributeFieldCommon.legalAddress:
                field = React.createElement(FieldWithServerError, { name: attribute, label: label });
                break;
            case AttributeFieldCommon.state:
                field = React.createElement(AutocompleteField, { fieldName: attribute, label: label, options: stateOptions });
                break;
            case AttributeFieldCommon.opf:
                field = React.createElement(AutocompleteField, { fieldName: attribute, label: label, options: opfOptions });
                break;
        }
        return (React.createElement(Grid, { item: true, key: attribute }, field));
    });
    return (React.createElement(Dialog, { id: id, fullWidth: true, maxWidth: "md", open: true, onKeyDown: stopPropagation },
        React.createElement(DialogTitle, { onCloseClick: closeCreateModal },
            React.createElement(FormattedMessage, { id: "formio.organization.new" })),
        React.createElement(Formik, { initialValues: initialValues, validationSchema: validationSchema, onSubmit: createOrganization }, function () {
            // Реагирует на любое изменение формы
            setIsChanged && setIsChanged();
            return (React.createElement(Form, { onKeyDown: disableSubmitOnEnterKeyPress },
                React.createElement(DialogContent, null,
                    React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap", spacing: 1 },
                        React.createElement(Grid, { item: true },
                            React.createElement(FieldWithServerError, { name: "fullName", label: formatMessage({ id: 'formio.organization.attribute.fullName' }), required: true })),
                        React.createElement(Grid, { item: true },
                            React.createElement(FieldWithServerError, { name: "shortName", label: formatMessage({ id: 'formio.organization.attribute.shortName' }) })),
                        React.createElement(Grid, { item: true },
                            React.createElement(NumberField, { name: "inn", label: formatMessage({ id: 'formio.organization.attribute.inn' }), required: true })),
                        additionalFields)),
                React.createElement(DialogActions, { cancelButton: cancelButton, submitButton: submitButton })));
        })));
});
