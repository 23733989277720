import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { OrganizationFieldContext } from '../../../contexts';
import { OrganizationModel } from '../../../models';
import { OrganizationFieldEdit } from './organization-field-edit';
import { OrganizationFieldView } from './organization-field-view';
export var OrganizationField = observer(function (props) {
    var id = props.id, label = props.label, required = props.required, rootStore = props.rootStore, isByDaData = props.isByDaData, searchTrigger = props.searchTrigger, isAllowCreation = props.isAllowCreation, isViewOnly = props.isViewOnly, isDisabled = props.isDisabled, setValue = props.setValue, _a = props.value, value = _a === void 0 ? null : _a, _b = props.attributeFields, attributeFields = _b === void 0 ? [] : _b, triggerUnblock = props.triggerUnblock, triggerBlock = props.triggerBlock, setIsChanged = props.setIsChanged;
    var model = useMemo(function () { return new OrganizationModel({ rootStore: rootStore, isByDaData: isByDaData, searchTrigger: searchTrigger, value: value }); }, [rootStore, isByDaData, searchTrigger, value]);
    var contextValue = {
        id: id,
        model: model,
        label: label,
        required: required,
        isDisabled: isDisabled,
        isAllowCreation: isAllowCreation,
        attributeFields: attributeFields,
        setValue: setValue,
        triggerUnblock: triggerUnblock,
        triggerBlock: triggerBlock,
        setIsChanged: setIsChanged,
    };
    return (React.createElement(OrganizationFieldContext.Provider, { value: contextValue }, isViewOnly ? React.createElement(OrganizationFieldView, null) : React.createElement(OrganizationFieldEdit, null)));
});
