/**
 * Добавляет поле в объект, если есть значение (значение не undefined, null, '')
 * @param object Объект куда нужно добавить поле
 * @param propertyTitle Ключ объекта для установки значения
 * @param propertyValue Значение поля
 */
export var setIfHave = function (object, propertyTitle, propertyValue) {
    if (propertyValue) {
        object[propertyTitle] = propertyValue;
    }
};
