import { Grid, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useOrganizationFieldContext } from '../../../../hooks';
import { OrganizationCreateModal } from './OrganizationCreateModal';
import { OrganizationSearchField } from './organization-search-field';
export var OrganizationFieldEdit = observer(function () {
    var formatMessage = useIntl().formatMessage;
    var _a = useOrganizationFieldContext(), id = _a.id, isDisabled = _a.isDisabled, setValue = _a.setValue, _b = _a.attributeFields, attributeFields = _b === void 0 ? [] : _b, model = _a.model;
    var organizationStore = model.organizationStore, isCreateModalOpen = model.isCreateModalOpen, selectedOrganization = model.selectedOrganization;
    useEffect(function () {
        if (isDisabled) {
            // Нужно для совместной работы с формой.
            // После смены флага isDisabled, остаётся модалка (в dom) из старого инстанса
            var modalElement = document.querySelector("#" + id);
            modalElement && modalElement.remove();
        }
    }, [isDisabled]);
    useEffect(function () {
        organizationStore.loadPopClient();
    }, [organizationStore]);
    useEffect(function () {
        setValue && setValue(selectedOrganization);
    }, [selectedOrganization]);
    var attributeFieldsComponents = attributeFields.map(function (attribute) {
        var value = (selectedOrganization === null || selectedOrganization === void 0 ? void 0 : selectedOrganization[attribute]) || '-';
        var fieldValue = typeof value === 'object' ? value.title : value;
        return (React.createElement(Grid, { item: true },
            React.createElement(TextField, { label: formatMessage({ id: "formio.organization.attribute." + attribute }), value: fieldValue, fullWidth: true, disabled: true })));
    });
    return (React.createElement(React.Fragment, null,
        isCreateModalOpen && React.createElement(OrganizationCreateModal, null),
        React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap", spacing: 2 },
            React.createElement(Grid, { item: true },
                React.createElement(OrganizationSearchField, null)),
            Boolean(attributeFields.length) && (React.createElement(Grid, { item: true, container: true, direction: "column", wrap: "nowrap", spacing: 2 }, attributeFieldsComponents)))));
});
