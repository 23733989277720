var attribute = {
    inn: 'ИНН',
    opf: 'Организационно-правовая форма',
    state: 'Статус',
    fullName: 'Полное название организации',
    shortName: 'Краткое название организации',
    legalAddress: 'Адрес',
    fio: 'ФИО руководителя',
    ogrn: 'ОГРН/ОГРНИП',
    kpp: 'КПП',
};
export var organization = {
    searchPlaceholder: 'Введите название в свободной форме, ИНН или ОГРН',
    notFound: 'Организация не найдена',
    hasMoreResults: 'Выберите вариант или продолжите ввод',
    create: 'Создать новую организацию',
    new: 'Новая организация',
    invalidAttribute: 'Неверное значение {attribute}',
    attribute: attribute,
};
