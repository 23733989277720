import { organization } from './organization';
export var formio_en = {
    connectionFailed: 'Connection failed',
    waitConnection: 'Wait for the online connection to continue editing',
    connectionRestored: 'Connection restored',
    canContinue: 'You can continue editing',
    noConnection: 'No connection to server',
    impossibleReceiveData: 'Impossible to receive form data. Refresh the page',
    organization: organization,
};
