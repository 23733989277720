var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var AttributeFieldRequired;
(function (AttributeFieldRequired) {
    AttributeFieldRequired["fullName"] = "fullName";
    AttributeFieldRequired["shortName"] = "shortName";
    AttributeFieldRequired["inn"] = "inn";
})(AttributeFieldRequired || (AttributeFieldRequired = {}));
export var AttributeFieldCommon;
(function (AttributeFieldCommon) {
    AttributeFieldCommon["opf"] = "opf";
    AttributeFieldCommon["state"] = "state";
    AttributeFieldCommon["legalAddress"] = "legalAddress";
    AttributeFieldCommon["fio"] = "fio";
    AttributeFieldCommon["ogrn"] = "ogrn";
    AttributeFieldCommon["kpp"] = "kpp";
})(AttributeFieldCommon || (AttributeFieldCommon = {}));
export var AttributeField = __assign(__assign({}, AttributeFieldRequired), AttributeFieldCommon);
