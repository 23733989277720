import { getExpertiseTaskViewRoutes as getExpertiseTaskViewRoutesInj } from '@platform/mexp-front-core';
import { injectable } from 'react-magnetic-di';
import clientRoute from '../../clientRoute';

export const getExpertiseTaskViewRoutes = (withSubjectFilesTab: boolean): string[] => {
    const routes = [clientRoute.expertiseTask, clientRoute.expertiseTaskSubject, clientRoute.expertiseTasksInfo];
    if (withSubjectFilesTab) {
        routes.push(clientRoute.expertiseTaskFiles);
    }
    return routes;
};

export const getExpertiseTaskViewRoutesDI = injectable(getExpertiseTaskViewRoutesInj, getExpertiseTaskViewRoutes);
