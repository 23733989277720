var attribute = {
    inn: 'Tax identification number',
    opf: 'Organizational and legal form',
    state: 'Status',
    fullName: 'Full name of the organization',
    shortName: 'Short name of the organization',
    legalAddress: 'Address',
    fio: 'Full name of head',
    ogrn: 'Primary state registration number (opt. "of the Sole entrepreneur")',
    kpp: 'Tax Registration Reason Code',
};
export var organization = {
    searchPlaceholder: 'Enter the name of the organization, Tax identification number or Main state registration number',
    notFound: 'Organization could not be found',
    hasMoreResults: 'Select the option or continue typing',
    create: 'Create new organization',
    new: 'New organization',
    invalidAttribute: 'Incorrect value {attribute}',
    attribute: attribute,
};
