import { Env, RootStoreProps } from '@platform/front-core';
import { getPalette, TotPalette } from '@platform/front-ui';
import { createBrowserHistory } from 'history';

const projectName = process.env.REACT_APP_THEME_NAME;
const apiUrl = process.env.REACT_APP_API_URL;

const env: Env = {
    apiUrl: process.env.REACT_APP_API_URL,
    serviceUrl:
        process.env.REACT_APP_SERVICE_URL || typeof apiUrl === 'string' ? apiUrl?.replace('api', 'service') : undefined,
    totCopyrightRu: process.env.REACT_APP_TOT_COPYRIGHT_RU,
    totCopyrightEn: process.env.REACT_APP_TOT_COPYRIGHT_EN,
    totTel: process.env.REACT_APP_TOT_TEL,
    totMail: process.env.REACT_APP_TOT_MAIL,
    formServiceUrl: process.env.REACT_APP_FORM_UI_SERVICE_URL,
    termsOfServiceLinkRu: process.env.REACT_APP_TERMS_OF_SERVICE_RU,
    termsOfServiceLinkEn: process.env.REACT_APP_TERMS_OF_SERVICE_EN,
    yaMetrikaId: Number(process.env.REACT_APP_METRIKA_ID),
    asyncCheckDelay: Number(process.env.REACT_APP_ASYNC_CHECK_DELAY) || 200,
    asyncCheckTimeout: Number(process.env.REACT_APP_ASYNC_CHECK_TIMEOUT) || 2000,
};

let features: RootStoreProps['features'];
const palette: TotPalette = getPalette();
let ru = {};
let en = {};

if (projectName) {
    features = require(`./customization/${projectName}/features`).default;
    try {
        ru = require(`./customization/${projectName}/locales/ru`).default;
        en = require(`./customization/${projectName}/locales/en`).default;
    } catch (e) {}
}

const locales: RootStoreProps['locales'] = { ru, en };

const history = createBrowserHistory();

export { env, features, locales, history, palette };
