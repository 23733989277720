import { blue } from '@platform/front-ui';
import { getChunks } from 'highlight-string';
import Parser from 'html-react-parser';
// Возвращает переданный текст с цветовой подсветкой совпадений `searchValue`
export var getHighlightedString = function (searchValue, text) {
    if (!searchValue) {
        return text;
    }
    var chunks = getChunks({
        text: text,
        substrings: searchValue.split(' '),
    });
    return Parser(chunks
        .map(function (_a) {
        var highlighted = _a.highlighted, text = _a.text;
        return (highlighted ? "<span style=\"color: " + blue[400] + "\">" + text + "</span>" : text);
    })
        .join(''));
};
