import { Box, Button } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useOrganizationFieldContext } from '../../../../../hooks';
var sxStyles = makeSxStyles({
    createButtonWrapper: {
        height: '32px',
    },
    createButton: {
        position: 'absolute',
        right: 0,
        left: 0,
    },
});
export var OrganizationSearchFieldNoOptionsText = observer(function (props) {
    var createButtonRef = props.createButtonRef;
    var _a = useOrganizationFieldContext(), isAllowCreation = _a.isAllowCreation, model = _a.model;
    var isByDaData = model.isByDaData, openCreateModal = model.openCreateModal;
    return (React.createElement(React.Fragment, null,
        React.createElement(FormattedMessage, { id: "formio.organization.notFound" }),
        !isByDaData && isAllowCreation && (React.createElement(Box, { mt: 1, sx: sxStyles.createButtonWrapper },
            React.createElement(Button, { fullWidth: true, variant: "text", color: "secondary", ref: createButtonRef, sx: sxStyles.createButton, onClick: openCreateModal },
                React.createElement(FormattedMessage, { id: "formio.organization.create" }))))));
});
