import { CommentsTabIndicatorProps } from '@platform/front-core';
import {
    ExpertiseTaskViewTab,
    ExpertiseTaskViewTabSettings,
    getExpertiseTaskBodyDescriptionTabsInj,
} from '@platform/mexp-front-core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectable } from 'react-magnetic-di';
import clientRoute from '../../../../../clientRoute';

const commentsTabDefaultStatusIndicators: CommentsTabIndicatorProps['statuses'] = {
    hasUnreadComments: false,
    hasActiveComments: false,
};

export const getExpertiseTaskBodyDescriptionTabs = (): ExpertiseTaskViewTabSettings<string>[] => {
    const tabs: ExpertiseTaskViewTabSettings<ExpertiseTaskViewTab>[] = [
        {
            tabCode: ExpertiseTaskViewTab.subject,
            title: <FormattedMessage id="expertiseTask.tabs.subject" />,
            path: clientRoute.expertiseTaskSubject,
            ...commentsTabDefaultStatusIndicators,
        },
        {
            tabCode: ExpertiseTaskViewTab.task,
            title: <FormattedMessage id="expertiseTask.tabs.task" />,
            path: clientRoute.expertiseTasksInfo,
            ...commentsTabDefaultStatusIndicators,
        },
    ];
    return tabs;
};

export const getExpertiseTaskBodyDescriptionTabsDI = injectable(
    getExpertiseTaskBodyDescriptionTabsInj,
    getExpertiseTaskBodyDescriptionTabs,
);
