var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Box, Grid, ListItem, Typography } from '@mui/material';
import { grey } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useOrganizationFieldContext } from '../../../../../hooks';
import { getHighlightedString } from '../../../../../utils';
export var OrganizationSearchFieldOption = observer(function (props) {
    var option = props.option, params = __rest(props, ["option"]);
    var shortName = option.shortName, inn = option.inn, legalAddress = option.legalAddress, fullName = option.fullName;
    var _a = useOrganizationFieldContext().model, hasMoreResults = _a.hasMoreResults, firstOptionInn = _a.firstOptionInn, searchValue = _a.searchValue;
    var subTitle = (inn + " " + (legalAddress !== null && legalAddress !== void 0 ? legalAddress : '')).trim();
    var titleWithHighlight = getHighlightedString(searchValue, shortName || fullName);
    var subTitleWithHighlight = getHighlightedString(searchValue, subTitle);
    var showHasMoreResults = hasMoreResults && inn === firstOptionInn;
    return (React.createElement(React.Fragment, { key: inn },
        showHasMoreResults && (React.createElement(Box, { px: 2, pb: 1, pt: 0.5 },
            React.createElement(Typography, { color: grey[500], variant: "caption" },
                React.createElement(FormattedMessage, { id: "formio.organization.hasMoreResults" })))),
        React.createElement(ListItem, __assign({}, params),
            React.createElement(Grid, { container: true, direction: "column" },
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "subtitle2" }, titleWithHighlight)),
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { color: grey[600] }, subTitleWithHighlight))))));
});
