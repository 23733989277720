var ApiConfigs = /** @class */ (function () {
    function ApiConfigs() {
        // TODO Вынести в front-core ???
        Object.defineProperty(this, "getModulesApis", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                url: '/getAllRoutes',
                method: 'GET',
            }
        });
        Object.defineProperty(this, "getOrganizationCreationInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                url: '/organization/search/creationInfo',
                method: 'GET',
            }
        });
        Object.defineProperty(this, "getOrganizations", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (useDaData, searchQuery) {
                return {
                    url: '/organization/search',
                    method: 'GET',
                    params: {
                        useDaData: useDaData,
                        searchQuery: searchQuery,
                    },
                };
            }
        });
    }
    return ApiConfigs;
}());
export { ApiConfigs };
export var apiConfigs = new ApiConfigs();
