import { Grid, Typography } from '@mui/material';
import { CommonInfoLine, grey } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { useOrganizationFieldContext } from '../../../../hooks';
export var OrganizationFieldView = observer(function () {
    var formatMessage = useIntl().formatMessage;
    var _a = useOrganizationFieldContext(), label = _a.label, _b = _a.attributeFields, attributeFields = _b === void 0 ? [] : _b, model = _a.model;
    var selectedOrganization = model.selectedOrganization;
    var attributes = attributeFields.map(function (attribute) {
        var title = formatMessage({ id: "formio.organization.attribute." + attribute });
        var attributeInfo = selectedOrganization === null || selectedOrganization === void 0 ? void 0 : selectedOrganization[attribute];
        var value = typeof attributeInfo === 'object' ? attributeInfo.title : attributeInfo;
        return (React.createElement(Grid, { item: true, key: attribute },
            React.createElement(CommonInfoLine, { title: title, value: value, isColumnDirection: true })));
    });
    return (React.createElement(Grid, { container: true, direction: "column", flexWrap: "nowrap", spacing: 1 },
        React.createElement(Grid, { item: true },
            React.createElement(Typography, { color: grey[900], fontWeight: 700 }, label)),
        attributes));
});
