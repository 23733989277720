import { GlobalStyles } from '@mui/material';
import React from 'react';
import { sassVars } from '../../constants';
export var formioModalGlobalStyles = function (theme) { return (React.createElement(GlobalStyles, { styles: function () {
        return {
            '.formio-dialog': {
                zIndex: theme.zIndex.modal,
                position: 'fixed',
                inset: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                '&-overlay': {
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundColor: sassVars.formioModalOverlayBackground,
                },
                '&-content': {
                    zIndex: theme.zIndex.modal,
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: theme.shadows[24],
                    '.MuiButton-root': {
                        margin: "0 " + theme.spacing(0.5),
                    },
                    '.MuiDialogTitle-root': {
                        padding: theme.spacing(2) + " " + theme.spacing(3),
                    },
                    '.MuiDialogActions-root': {
                        padding: "" + theme.spacing(1),
                    },
                },
            },
        };
    } })); };
