import { IP } from '../constants';
export var isKpp = function (value) {
    var valueToString = value ? value.toString() : '';
    if (valueToString.length !== 9) {
        return false;
    }
    if (!valueToString.match(/\d{4}[\dA-Z][\dA-Z]\d{3}/)) {
        return false;
    }
    return true;
};
export var isInnIp = function (value) {
    var valueToString = value ? value.toString() : '';
    var getN = function (index) { return parseInt(valueToString[index]); };
    if (valueToString.length === 12) {
        var dgt11 = ((7 * getN(0) +
            2 * getN(1) +
            4 * getN(2) +
            10 * getN(3) +
            3 * getN(4) +
            5 * getN(5) +
            9 * getN(6) +
            4 * getN(7) +
            6 * getN(8) +
            8 * getN(9)) %
            11) %
            10;
        var dgt12 = ((3 * getN(0) +
            7 * getN(1) +
            2 * getN(2) +
            4 * getN(3) +
            10 * getN(4) +
            3 * getN(5) +
            5 * getN(6) +
            9 * getN(7) +
            4 * getN(8) +
            6 * getN(9) +
            8 * getN(10)) %
            11) %
            10;
        return getN(10) === dgt11 && getN(11) === dgt12;
    }
    return false;
};
export var isInn = function (value) {
    var valueToString = value ? value.toString() : '';
    var getN = function (index) { return parseInt(valueToString[index]); };
    if (valueToString.length === 10) {
        var dgt10 = ((2 * getN(0) +
            4 * getN(1) +
            10 * getN(2) +
            3 * getN(3) +
            5 * getN(4) +
            9 * getN(5) +
            4 * getN(6) +
            6 * getN(7) +
            8 * getN(8)) %
            11) %
            10;
        return getN(9) === dgt10;
    }
    return false;
};
export var isOgrnIp = function (value) {
    var valueToString = value ? value.toString() : '';
    if (valueToString.length === 15) {
        var num14 = Math.floor((Number(value) / 10) % 13);
        var dgt15 = num14 % 10;
        return parseInt(value.toString()[14]) === dgt15;
    }
    return false;
};
export var isOgrn = function (value) {
    var valueToString = value ? value.toString() : '';
    if (valueToString.length === 13) {
        var num12 = Math.floor((Number(value) / 10) % 11);
        var dgt13 = num12 === 10 ? 0 : num12;
        return parseInt(valueToString[12]) === dgt13;
    }
    return false;
};
export var testInn = function (value, validationContext) {
    if (!value) {
        return true;
    }
    var opf = validationContext.parent.opf;
    return opf ? (opf.code === IP ? isInnIp(value) : isInn(value)) : isInn(value) || isInnIp(value);
};
export var testKpp = function (value) {
    if (!value) {
        return true;
    }
    return isKpp(value);
};
export var testOgrn = function (value, validationContext) {
    if (!value) {
        return true;
    }
    var opf = validationContext.parent.opf;
    return opf ? (opf.code === IP ? isOgrnIp(value) : isOgrn(value)) : isOgrn(value) || isOgrnIp(value);
};
