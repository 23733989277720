var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ThemeProvider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { RawIntlProvider } from 'react-intl';
import { OrganizationField } from './OrganizationField';
// Обертка для использования вне модуля, в модуле можно использовать сразу `OrganizationField`
export var OrganizationFieldApp = observer(function (props) {
    var theme = props.theme, rootStore = props.rootStore, otherProps = __rest(props, ["theme", "rootStore"]);
    var intlStore = rootStore.intlStore;
    return (React.createElement(RawIntlProvider, { value: intlStore.intl },
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(OrganizationField, __assign({}, otherProps, { rootStore: rootStore })))));
});
