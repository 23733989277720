var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { LoadingModel } from '@platform/front-utils';
import debounce from 'debounce';
import { action, computed, makeObservable, observable } from 'mobx';
import { OrganizationStore } from '../../stores';
import { setIfHave } from '../../utils';
export var organizationModelObservables = {
    organizationStore: observable,
    isByDaData: observable,
    searchValue: observable,
    options: observable,
    hasMoreResults: observable,
    selectedOrganization: observable,
    wasSearching: observable,
    isCreateModalOpen: observable,
    stateOptions: observable,
    opfOptions: observable,
    isSearchDisabled: computed,
    firstOptionInn: computed,
    search: action.bound,
    loadCreationOptions: action.bound,
    createOrganization: action.bound,
    openCreateModal: action.bound,
    closeCreateModal: action.bound,
    dropSearchResultFields: action.bound,
    dropSearchValue: action.bound,
    setSearchValue: action.bound,
    setSearchResultFields: action.bound,
    setSelectedOrganization: action.bound,
    setWasSearching: action.bound,
    setCreateOptions: action.bound,
};
var OrganizationModel = /** @class */ (function (_super) {
    __extends(OrganizationModel, _super);
    function OrganizationModel(props) {
        var _this = _super.call(this) || this;
        Object.defineProperty(_this, "organizationStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "searchTrigger", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "isByDaData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "searchValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(_this, "options", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "hasMoreResults", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "selectedOrganization", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "wasSearching", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "isCreateModalOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "stateOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "opfOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "search", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: debounce(function () {
                _this.startLoading();
                _this.dropSearchResultFields();
                return _this.organizationStore
                    .search(_this.searchValue)
                    .then(_this.setSearchResultFields)
                    .finally(function () {
                    _this.stopLoading();
                    _this.setWasSearching(true);
                });
            }, 500)
        });
        var rootStore = props.rootStore, isByDaData = props.isByDaData, searchTrigger = props.searchTrigger, value = props.value;
        makeObservable(_this, organizationModelObservables);
        _this.organizationStore = new OrganizationStore(rootStore, isByDaData);
        _this.isByDaData = isByDaData;
        _this.searchTrigger = searchTrigger;
        _this.selectedOrganization = value;
        _this.stopLoading();
        return _this;
    }
    Object.defineProperty(OrganizationModel.prototype, "isSearchDisabled", {
        get: function () {
            return this.organizationStore.isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrganizationModel.prototype, "firstOptionInn", {
        get: function () {
            return this.options.length ? this.options[0].inn : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrganizationModel.prototype, "loadCreationOptions", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.organizationStore.getCreationInfo().then(this.setCreateOptions);
        }
    });
    Object.defineProperty(OrganizationModel.prototype, "createOrganization", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (formValues) {
            var fio = formValues.fio, inn = formValues.inn, kpp = formValues.kpp, opf = formValues.opf, ogrn = formValues.ogrn, shortName = formValues.shortName, fullName = formValues.fullName, state = formValues.state, legalAddress = formValues.legalAddress;
            var dto = {
                fullName: fullName,
                inn: inn,
            };
            setIfHave(dto, 'shortName', shortName);
            setIfHave(dto, 'fio', fio);
            setIfHave(dto, 'kpp', kpp);
            setIfHave(dto, 'opf', opf);
            setIfHave(dto, 'ogrn', ogrn);
            setIfHave(dto, 'state', state);
            setIfHave(dto, 'legalAddress', legalAddress);
            this.setSelectedOrganization(dto);
            this.closeCreateModal();
            setTimeout(function () {
                // После закрытия модалки фокус падает на `SearchField`, убираем фокус
                if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur();
                }
            });
        }
    });
    Object.defineProperty(OrganizationModel.prototype, "openCreateModal", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.isCreateModalOpen = true;
        }
    });
    Object.defineProperty(OrganizationModel.prototype, "closeCreateModal", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.isCreateModalOpen = false;
        }
    });
    Object.defineProperty(OrganizationModel.prototype, "dropSearchResultFields", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.hasMoreResults = false;
            this.options = [];
            this.setWasSearching(false);
        }
    });
    Object.defineProperty(OrganizationModel.prototype, "dropSearchValue", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.searchValue = '';
        }
    });
    Object.defineProperty(OrganizationModel.prototype, "setSearchValue", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (searchValue) {
            this.searchValue = searchValue;
        }
    });
    Object.defineProperty(OrganizationModel.prototype, "setSearchResultFields", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (dto) {
            var hasMoreResults = dto.hasMoreResults, options = dto.options;
            this.hasMoreResults = hasMoreResults;
            this.options = options;
        }
    });
    Object.defineProperty(OrganizationModel.prototype, "setSelectedOrganization", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (selectedOrganization) {
            this.selectedOrganization = selectedOrganization;
        }
    });
    Object.defineProperty(OrganizationModel.prototype, "setWasSearching", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (wasSearching) {
            this.wasSearching = wasSearching;
        }
    });
    Object.defineProperty(OrganizationModel.prototype, "setCreateOptions", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (dto) {
            this.stateOptions = dto.states;
            this.opfOptions = dto.opfs;
        }
    });
    return OrganizationModel;
}(LoadingModel));
export { OrganizationModel };
