import { GlobalStyles } from '@mui/material';
import React from 'react';
import { sassVars } from '../../constants';
export var flatpickrGlobalStyles = function (theme) { return (React.createElement(GlobalStyles
// @ts-ignore
, { 
    // @ts-ignore
    styles: function () {
        return {
            '.flatpickr-calendar': {
                '&.arrowTop, &.arrowBottom': {
                    padding: sassVars.datepickerContainerPadding + " !important",
                    border: sassVars.datepickerContainerBorder + " !important",
                    boxShadow: sassVars.datepickerBoxShadow + " !important",
                    '&:before, &:after': {
                        left: '50% !important', // выравнивание стрелки по центру календаря
                    },
                    '&:before': {
                        borderWidth: sassVars.datepickerArrowBeforeSize + " !important",
                        margin: "0 -" + sassVars.datepickerArrowBeforeSize + " !important",
                    },
                    '&:after': {
                        borderWidth: sassVars.datepickerArrowAfterSize + " !important",
                        margin: "0 -" + sassVars.datepickerArrowAfterSize + " !important",
                    },
                    '.numInputWrapper:hover': {
                        backgroundColor: "transparent !important",
                        '.numInput': {
                            background: sassVars.inputBackgroundColor + " !important",
                            '&:not(:focus)': {
                                borderColor: sassVars.inputHoverBorderColor + " !important",
                            },
                        },
                    },
                    '.flatpickr-monthDropdown-months, .numInput': {
                        fontFamily: sassVars.inputFontFamily + " !important",
                        fontWeight: sassVars.inputFontWeight + " !important",
                        fontSize: sassVars.datepickerFontSize + " !important",
                        lineHeight: sassVars.datepickerLineHeight + " !important",
                        border: sassVars.inputBorderStyle + " " + sassVars.backgroundMainColor + " !important",
                        borderWidth: sassVars.datepickerInputBorderWidth + " !important",
                        '&:hover, &:focus': {
                            background: sassVars.inputBackgroundColor + " !important",
                        },
                        '&:hover': {
                            borderColor: sassVars.inputHoverBorderColor + " !important",
                        },
                        '&:focus, &:focus:hover': {
                            borderColor: sassVars.inputFocusBorderColor + " !important",
                        },
                    },
                    '.flatpickr-months': {
                        '.flatpickr-prev-month, .flatpickr-next-month': {
                            position: "relative !important",
                            padding: "0 !important",
                            width: sassVars.datepickerBaseSize + " !important",
                            height: sassVars.datepickerBaseSize + " !important",
                            '.flatpickr-icon:before': {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                fontFamily: 'glyphicons-theme',
                                content: sassVars.datePickerIcon,
                                fontSize: "11px",
                                fontStyle: 'normal',
                                transform: 'rotate(90deg)',
                            },
                        },
                        '.flatpickr-next-month .flatpickr-next-icon:before': {
                            transform: 'rotate(-90deg)',
                        },
                        '.flatpickr-month, .flatpickr-current-month': {
                            height: "" + sassVars.datepickerBaseSize,
                        },
                        '.flatpickr-current-month': {
                            width: "100% !important",
                            left: "0 !important",
                            padding: "0 !important",
                            fontSize: sassVars.datepickerFontSize + " !important",
                            lineHeight: sassVars.datepickerLineHeight + " !important",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '.flatpickr-monthDropdown-months': {
                                margin: "0 " + sassVars.datepickerBaseMargin + " 0 0",
                                padding: "0 " + sassVars.datepickerInputPaddingHorizMin,
                            },
                            '.numInputWrapper': {
                                width: "" + sassVars.datepickerInputWidth,
                            },
                            '.numInput': {
                                padding: "0 " + sassVars.datepickerInputPaddingHorizMin + " 0 " + sassVars.datepickerInputPaddingHoriz,
                            },
                        },
                    },
                    '.flatpickr-innerContainer': {
                        '.flatpickr-rContainer': {
                            position: 'relative',
                            width: '100%',
                        },
                        '.flatpickr-weekdays': {
                            height: "" + sassVars.datepickerBaseSize,
                            margin: sassVars.datepickerBaseMargin + " 0",
                        },
                        '.flatpickr-weekday': {
                            fontFamily: "" + sassVars.datepickerWeekdayFontFamily,
                            fontSize: "" + sassVars.datepickerWeekdayFontSize,
                            fontWeight: "" + sassVars.datepickerWeekdayFontWeight,
                            lineHeight: "" + sassVars.datepickerWeekdayLineHeight,
                            textTransform: "" + sassVars.datepickerWeekdayTextTransform,
                            letterSpacing: "" + sassVars.datepickerWeekdayLetterSpacing,
                            color: "" + sassVars.datepickerWeekdayColor,
                            width: "" + sassVars.datepickerBaseSize,
                            marginRight: "" + sassVars.datepickerBaseMargin,
                        },
                        '.flatpickr-days, .dayContainer': {
                            width: '100%',
                            minWidth: '100%',
                            maxWidth: '100%',
                        },
                        '.flatpickr-day': {
                            maxWidth: "" + sassVars.datepickerBaseSize,
                            height: "" + sassVars.datepickerBaseSize,
                            lineHeight: "" + sassVars.datepickerBaseSize,
                            marginRight: "" + sassVars.datepickerBaseMargin,
                            marginBottom: "" + sassVars.datepickerSmallMargin,
                            border: 'none',
                            borderRadius: "" + sassVars.datepickerDayBorderRadius,
                            color: "" + theme.palette.secondary.main,
                            '&:hover': {
                                backgroundColor: "" + theme.palette.secondary.contrastText,
                            },
                            '&.prevMonthDay, &.nextMonthDay': {
                                color: 'rgba(57, 57, 57, 0.3)',
                            },
                            '&.today': {
                                color: "" + sassVars.datepickerDayTodayColor,
                            },
                            '&.selected': {
                                background: "" + theme.palette.primary.main,
                                color: "" + theme.palette.primary.contrastText,
                                '&:hover': {
                                    color: "" + theme.palette.primary.contrastText,
                                },
                            },
                        },
                    },
                },
                '&.arrowTop': {
                    marginTop: "9px !important",
                    '&:before': {
                        borderBottomColor: sassVars.datepickerContainerBorderColor + " !important",
                    },
                },
                '&.arrowBottom': {
                    marginTop: "-44px !important",
                    '&:before': {
                        borderTopColor: sassVars.datepickerContainerBorderColor + " !important",
                    },
                },
                '&.showTimeInput.hasTime .flatpickr-time': {
                    borderTop: sassVars.datepickerTimeBorderTop + " !important",
                    paddingTop: sassVars.datepickerTimePaddingTop + " !important",
                    height: sassVars.datepickerTimeHeight + " !important",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '.numInputWrapper, .flatpickr-time-separator': {
                        height: "" + sassVars.datepickerLineHeight,
                        lineHeight: "" + sassVars.datepickerLineHeight,
                    },
                    '.numInputWrapper': {
                        flex: 0,
                    },
                    '.flatpickr-hour, .flatpickr-minute': {
                        width: sassVars.datepickerInputWidth + " !important",
                    },
                    '.flatpickr-hour': {
                        textAlign: 'right',
                        paddingRight: "" + sassVars.datepickerInputHourPadding,
                    },
                    '.flatpickr-minute': {
                        textAlign: 'left',
                        paddingLeft: "" + sassVars.datepickerInputPaddingHoriz,
                        marginLeft: "" + sassVars.datepickerInputMinuteMargin,
                    },
                },
            },
            '.tooltip': {
                opacity: 1,
                left: '10px !important',
            },
        };
    } })); };
