import { organization } from './organization';
export var formio_ru = {
    connectionFailed: 'Отсутствует подключение к интернету',
    waitConnection: 'Дождитесь восстановления интернет-соединения, чтобы продолжить редактирование',
    connectionRestored: 'Подключение восстановлено',
    canContinue: 'Вы можете продолжить редактирование',
    noConnection: 'Нет подключения к серверу',
    impossibleReceiveData: 'Невозможно получить данные формы. Обновите страницу',
    organization: organization,
};
