import React from 'react';
import ReactDOM from 'react-dom';
import 'setimmediate'; // setimmediate нужно для работы с react-scripts@5.0.0
import { Root } from './Root';

ReactDOM.render(
    <React.StrictMode>
        <Root />
    </React.StrictMode>,
    document.getElementById('root'),
);
