import { Grid } from '@mui/material';
import { AuthorizationCheck, sxHeight100 } from '@platform/front-core';
import { RouteParamsDefault, useCoreStore } from '@platform/front-utils';
import {
    borderSolid300,
    ExpertiseTaskSubject,
    ExpertiseTaskSubjectWithoutFiles,
    ExpertiseTaskView,
    ExpertiseTaskViewContentLeftSide as ExpertiseTaskViewContentLeftSideInj,
    ExpertiseTaskViewContentLeftSideTabs,
    getAdditionalTaskViewRoutesPagesInj as getAdditionalTaskViewRoutesPages,
    PermissionsStore,
} from '@platform/mexp-front-core';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { injectable } from 'react-magnetic-di';
import { generatePath, Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import clientRoute from '../../../../../clientRoute';

export const ExpertiseTaskViewContentLeftSide = observer((): JSX.Element => {
    const [withSubjectFilesTab] = useFeature('withSubjectFilesTab');
    const { id } = useParams<RouteParamsDefault>();

    const { permissionsStore } = useCoreStore();
    const { expertiseTaskConfig } = permissionsStore as PermissionsStore;
    const viewSubjectQuery = expertiseTaskConfig.viewSubject(id);

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === generatePath(clientRoute.expertiseTask, { id })) {
            history.replace(generatePath(clientRoute.expertiseTaskSubject, { id }));
        }
    }, [location.pathname, history, id]);

    const subjectTabContent = (allowed: boolean): JSX.Element =>
        allowed ? (
            withSubjectFilesTab ? (
                <React.Fragment>
                    <Route path={clientRoute.expertiseTaskSubject} exact>
                        <ExpertiseTaskSubjectWithoutFiles />
                    </Route>
                    <Route path={clientRoute.expertiseTaskFiles} exact>
                        <ExpertiseTaskSubject isOnlyFiles={true} />
                    </Route>
                </React.Fragment>
            ) : (
                <Route path={clientRoute.expertiseTaskSubject} exact>
                    <ExpertiseTaskSubject />
                </Route>
            )
        ) : (
            <Redirect to={generatePath(clientRoute.expertiseTasksInfo, { id })} />
        );

    return (
        <Grid container direction="column" wrap="nowrap" width="100%" sx={sxHeight100} overflow="hidden">
            <Grid item borderBottom={borderSolid300}>
                <ExpertiseTaskViewContentLeftSideTabs commentsConfig={null} />
            </Grid>
            <Grid item pt={1} pr={2} sx={sxHeight100} overflow="auto">
                <Switch>
                    <Route path={clientRoute.expertiseTaskSubject}>
                        <AuthorizationCheck {...viewSubjectQuery}>{subjectTabContent}</AuthorizationCheck>
                    </Route>
                    <Route path={clientRoute.expertiseTasksInfo} exact>
                        <ExpertiseTaskView />
                    </Route>
                    {getAdditionalTaskViewRoutesPages()}
                </Switch>
            </Grid>
        </Grid>
    );
});

export const ExpertiseTaskViewContentLeftSideDI = injectable(
    ExpertiseTaskViewContentLeftSideInj,
    ExpertiseTaskViewContentLeftSide,
);
