import { apiConfigs, Root as RootMEXP } from '@platform/mexp-front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Router } from 'react-router-dom';
import { ExpertiseTaskViewContentLeftSideDI, getExpertiseTaskBodyDescriptionTabsDI } from './di';
import { getExpertiseTaskViewRoutesDI } from './di/utils';
import { env, features, history, locales, palette } from './initRootProps';

export const Root = observer((): JSX.Element => {
    return (
        <Router history={history}>
            <RootMEXP
                apiConfigs={apiConfigs}
                env={env}
                features={features}
                history={history}
                locales={locales}
                palette={palette}
                use={[
                    getExpertiseTaskBodyDescriptionTabsDI,
                    ExpertiseTaskViewContentLeftSideDI,
                    getExpertiseTaskViewRoutesDI,
                ]}
            />
        </Router>
    );
});
