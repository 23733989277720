var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Autocomplete } from '@mui/material';
import { identity } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { organizationDefaultTriggerSignCount } from '../../../../../constants';
import { useOrganizationFieldContext } from '../../../../../hooks';
import { OrganizationSearchFieldInput } from './OrganizationSearchFieldInput';
import { OrganizationSearchFieldNoOptionsText } from './OrganizationSearchFieldNoOptionsText';
import { OrganizationSearchFieldOption } from './OrganizationSearchFieldOption';
var getOptionLabel = function (option) {
    return option.shortName || option.fullName;
};
export var OrganizationSearchField = observer(function () {
    var _a = useOrganizationFieldContext(), isDisabled = _a.isDisabled, model = _a.model;
    var isLoading = model.isLoading, options = model.options, selectedOrganization = model.selectedOrganization, searchValue = model.searchValue, isSearchDisabled = model.isSearchDisabled, wasSearching = model.wasSearching, searchTrigger = model.searchTrigger, search = model.search, setSearchValue = model.setSearchValue, setSelectedOrganization = model.setSelectedOrganization;
    var isByEnter = searchTrigger.isByEnter, _b = searchTrigger.triggerSignCount, triggerSignCount = _b === void 0 ? organizationDefaultTriggerSignCount : _b;
    var _c = __read(useState(false), 2), open = _c[0], setIsOpen = _c[1];
    var createButtonRef = useRef(null);
    var disabled = isDisabled || isSearchDisabled;
    useEffect(function () {
        if (!isByEnter && searchValue.length >= triggerSignCount) {
            search();
        }
    }, [isByEnter, triggerSignCount, searchValue]);
    useEffect(function () {
        setIsOpen(!isLoading && wasSearching);
    }, [searchValue, isLoading, wasSearching]);
    var onChange = function (_, value) {
        setSearchValue('');
        setSelectedOrganization(value);
    };
    var onInputChange = function (event, value) {
        event && setSearchValue(event.type === 'change' ? value : '');
    };
    var renderInput = function (props) {
        return React.createElement(OrganizationSearchFieldInput, __assign({}, props, { createButtonRef: createButtonRef }));
    };
    var renderOption = function (props, option) {
        return React.createElement(OrganizationSearchFieldOption, __assign({}, props, { option: option }));
    };
    var noOptionsText = React.createElement(OrganizationSearchFieldNoOptionsText, { createButtonRef: createButtonRef });
    return (React.createElement(Autocomplete, { options: options, renderOption: renderOption, getOptionLabel: getOptionLabel, renderInput: renderInput, onInputChange: onInputChange, filterOptions: identity, disabled: disabled, noOptionsText: noOptionsText, value: selectedOrganization, onChange: onChange, blurOnSelect: true, open: open }));
});
