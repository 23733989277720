import { LocalesTree, recordToLang } from '@platform/front-utils';
import { ttable_ru } from '@platform/ttable';
import startPage from './startPage';

const lang: LocalesTree = recordToLang(
    {
        homePage: startPage,
        productTitle: 'TOT EXP',
        startPage,
        ttable: ttable_ru,
    },
    false,
);

export default lang;
