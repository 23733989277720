var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, Grid, IconButton, TextField } from '@mui/material';
import { grey, makeSxStyles, FieldLabel } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { useOrganizationFieldContext } from '../../../../../hooks';
var sxStyles = makeSxStyles({
    loader: {
        marginTop: '5px',
    },
    searchField: {
        '[class*="MuiAutocomplete-inputRoot"]': {
            paddingRight: function (theme) { return theme.spacing(9) + ' !important'; },
        },
        legend: {
            // Скрывает раму за лейблом, когда поле заблокировано другим пользователем
            backgroundColor: grey[0],
            visibility: 'visible',
        },
    },
    inputEndAdornmentWrapper: {
        width: 'auto',
        position: 'absolute',
        right: function (theme) { return theme.spacing(0.5); },
    },
});
var focusClass = 'Mui-focusVisible';
export var OrganizationSearchFieldInput = observer(function (props) {
    var createButtonRef = props.createButtonRef, params = __rest(props, ["createButtonRef"]);
    var _a = useOrganizationFieldContext(), label = _a.label, required = _a.required, model = _a.model, triggerBlock = _a.triggerBlock, triggerUnblock = _a.triggerUnblock, setIsChanged = _a.setIsChanged;
    var formatMessage = useIntl().formatMessage;
    var isLoading = model.isLoading, search = model.search, searchValue = model.searchValue, isCreateModalOpen = model.isCreateModalOpen, searchTrigger = model.searchTrigger, dropSearchResultFields = model.dropSearchResultFields, dropSearchValue = model.dropSearchValue, openCreateModal = model.openCreateModal;
    var isByEnter = searchTrigger.isByEnter;
    var isSearchNotEmpty = Boolean(searchValue.length);
    var inputRef = useRef(null);
    var onKeyDown = function (event) {
        var _a, _b, _c;
        if (event.key === 'Enter' && !isCreateModalOpen && ((_a = createButtonRef.current) === null || _a === void 0 ? void 0 : _a.classList.contains(focusClass))) {
            openCreateModal();
            return;
        }
        if (event.key === 'ArrowDown') {
            (_b = createButtonRef.current) === null || _b === void 0 ? void 0 : _b.classList.add(focusClass);
        }
        else {
            (_c = createButtonRef.current) === null || _c === void 0 ? void 0 : _c.classList.remove(focusClass);
        }
        // setTimeout нужен т.к. onBlur срабатывает позже
        isByEnter &&
            setTimeout(function () {
                // Если фокус ещё на поле
                if (inputRef.current === document.activeElement) {
                    if (event.key === 'Enter' && isSearchNotEmpty) {
                        search();
                    }
                }
            });
    };
    var onBlur = function () {
        dropSearchValue();
        dropSearchResultFields();
        // Для совместной работы с формой
        !isCreateModalOpen && (triggerUnblock === null || triggerUnblock === void 0 ? void 0 : triggerUnblock());
    };
    var fieldLabel = React.createElement(FieldLabel, { required: required, text: label });
    var inputLabelProps = { shrink: true };
    var inputEndAdornment = (React.createElement(Grid, { container: true, direction: "row", wrap: "nowrap", alignItems: "center", spacing: 1, sx: sxStyles.inputEndAdornmentWrapper },
        isLoading && (React.createElement(Grid, { item: true },
            React.createElement(CircularProgress, { color: "secondary", size: 20, sx: sxStyles.loader }))),
        isByEnter && (React.createElement(Grid, { item: true },
            React.createElement(IconButton, { disabled: !isSearchNotEmpty, size: "large", onClick: search, tabIndex: -1 },
                React.createElement(SearchIcon, null))))));
    var inputProps = __assign(__assign({}, params.InputProps), { endAdornment: inputEndAdornment });
    return (React.createElement(TextField, __assign({}, params, { onKeyDown: onKeyDown, InputLabelProps: inputLabelProps, sx: sxStyles.searchField, inputRef: inputRef, onBlur: onBlur, onFocus: triggerBlock, onInput: setIsChanged, InputProps: inputProps, label: fieldLabel, placeholder: formatMessage({ id: 'formio.organization.searchPlaceholder' }) })));
});
