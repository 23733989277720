import { CSSProperties } from 'react';
import logoEn from '../../resources/images/misis-psal-logo_en.svg';
import logoRu from '../../resources/images/misis-psal-logo_ru.svg';
import headerLogoEn from '../../resources/images/misis-psal-logo-black_en.svg';
import headerLogoRu from '../../resources/images/misis-psal-logo-black_ru.svg';
import { ExpertiseFeatures } from '../../types';

const headerLogoStyles: CSSProperties = {
    height: '50px',
};

const startLogoStyles: CSSProperties = {
    height: '100px',
};

const config: ExpertiseFeatures = {
    customLogo: {
        isEnabled: true,
        locales: {
            en: {
                mdLogo: false,
                src: logoEn,
                style: startLogoStyles,
                alt: 'MISIS',
            },
            ru: {
                mdLogo: false,
                src: logoRu,
                style: startLogoStyles,
                alt: 'МИСИС',
            },
        },
    },
    esiaLogin: false,
    footerLogo: false,
    headerLogo: {
        isEnabled: true,
        locales: {
            en: {
                alt: 'TOT EXP logo',
                image: headerLogoEn,
                style: headerLogoStyles,
            },
            ru: {
                alt: 'TOT EXP логотип',
                image: headerLogoRu,
                style: headerLogoStyles,
            },
        },
    },
    helpIcon: false,
    modularNavigation: true,
    phoneNumber: false,
    userAdding: true,
    userAssignPassword: true,
    withSubjectFilesTab: false,
    yaMetrika: true,
    globalSearch: true,
};

export default config;
